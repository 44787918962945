import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import logoSvg from '../src/Assets/logo.svg';
import { differenceInYears } from 'date-fns';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import { GoogleLogin } from '@react-oauth/google';
// import FacebookLogin from 'react-facebook-login';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useAppContext } from './AppContextProvider';

export const Signup = ({ }) => {
  const { popHandel, setPopHandel } = useAppContext()
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // 00000000000000000
  const initialFormData = {
    fname: "",
    lname: "",
    name: "",
    email: "",
    phone: "",
    dob: null,
    password: "",
    cpassword: null,

  }
  const [formData, setFormData] = useState(initialFormData);
  const [referralCode, setReferralCode] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get('referralCode');
    if (referralCode) {
      setFormData({
        ...formData,
        referred: referralCode || ''
      });
    }
  }, []);

  // 000000000000000000
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  const [errors, setErrors] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    dob: null,
    password: '',
    cpassword: ''
  });


  const userSchema = Yup.object().shape({
    fname: Yup.string().required("Name Is Required!"),
    lname: Yup.string().required("Name Is Required!"),
    phone: Yup.string()
      // .matches(/^[0-9]+$/, "Phone number must be numeric")
      .matches(/^\+?[0-9 ]+$/, "Phone number must be in the correct format, +1 000000")
      .required("Phone Is Required!"),
    email: Yup.string().email("Invalid Email Format!").required("Email Is Required!"),
    dob: Yup.date().test(
      'is 21',
      'You must be at least 21 years old',
      value => differenceInYears(new Date(), new Date(value)) >= 21
    ).required("DOB Is Required!"),
    password: Yup.string()
      .min(6, 'Field must be at least 6 characters long')
      .max(12, 'Field must be less than 12 characters long')
      .required('Password Is Required!'),
    cpassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password fields do not match')
      .required('Confirm Password Is Required!')
  });

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!formData.name) {
      valid = false;
      errors.name = 'Field must not be empty';
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!formData?.email || !emailRegex.test(formData?.email)) {
      valid = false;
      errors.email = 'Invalid value';
    }

    if (formData?.password?.length < 6 || formData?.password?.length > 12) {
      valid = false;
      errors.password = 'Field must be at least 6 characters long and less than 12';
    }

    if (formData?.password !== formData?.cpassword) {
      valid = false;
      errors.cpassword = 'Password fields do not match';
    }
    const birthDate = new Date(formData.dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    // Check if age is less than 21
    if (age < 21) {
      valid = false;
      setErrors({ dob: 'You are not eligible for this site' });
      return;
    }
    setErrors(errors);
    return valid;
  };


  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormData(prevState => {
      const newState = { ...prevState, [name]: value };
      if (name === 'fname' || name === 'lname') {
        newState.name = `${newState.fname} ${newState.lname}`.trim();
      }
      return newState;
    });
  };
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: `+${value}`
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const errorsP1 = {};

      try {
        await userSchema.validate(formData, { abortEarly: false });
      } catch (error) {
        error.inner.forEach(err => {
          errorsP1[err.path] = err.message;
        });
      }
      setErrors(errorsP1);
      const { cpassword, ...dataToSend } = formData; // Exclude cpassword from the data sent to the API
      axios.post('https://api.vapedelivery.com/api/auth/signup', formData, {
        withCredentials: false
      }).then(response => {
        navigate('/product?userid=' + response.data.UserId);
        Swal.fire({
          icon: 'sucess',
          title: `Sign Up Successful Please LogIn now!`,
          showConfirmButton: true,
          timer: 4000
        });
        window.location.reload();
        // navigate('/login')
      }).catch(error => {
        console.error('Error fetching data:', error.response.data.phone);
        setErrors(error?.response?.data)

      });

    } catch (error) {
      // Catching other potential errors
      console.error("Unexpected Error:", error.response.data);
      setErrors(error?.response?.data)
    }

    // -----------------------
    // if (validate()) {
    //   const { cpassword, ...dataToSend } = formData; // Exclude cpassword from the data sent to the API
    //   axios.post('https://api.vapedelivery.com/api/auth/signup', formData, {
    //     withCredentials: false
    //   }).then(response => {
    //     // Assuming the response contains a UserId field
    //     navigate('/product?userid=' + response.data.UserId);
    //   }).catch(error => {
    //     console.error('Error fetching data:', error);
    //   });
    // }
  };

  // const handleGoogleSuccess = (response) => {
  //   const idToken = response.credential;
  //   axios.post('https://api.vapedelivery.com/api/auth/google-signup', { token: idToken })
  //     .then(response => {
  //       navigate('/product?userid=' + response.data.UserId);
  //     }).catch(error => {
  //       console.error('Google signup error:', error);
  //     });
  // };

  // const handleFacebookSuccess = (response) => {
  //   axios.post('https://api.vapedelivery.com/api/auth/facebook-signup', { token: response.accessToken })
  //     .then(response => {
  //       navigate('/product?userid=' + response.data.UserId);
  //     }).catch(error => {
  //       console.error('Facebook signup error:', error);
  //     });
  // };
  const inputStyle = {
    width: '100%',
    // padding: '0.75rem',
    border: '1px solid #E5E7EB', // border-gray-200
    borderRadius: '9999px', // rounded-full
    outline: 'none',
    paddingTop: '20px ',
    paddingBottom: '20px ',
    transition: 'border-color 0.2s ease-in-out' // Optional: smooth transition for border color change
  };
  const focusStyle = isFocused ? { borderColor: '#FB923C' } : {}; // focus:border-orange-500

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto " >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
          <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
        <div className={`sm:my-8   sm:max-w-lg sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className={`bg-white px-4  sm:p-6 sm:pb-4 relative  `}>

            <svg onClick={() => setPopHandel(0)}
              // onClick={() => navigate('/product')}
              className="absolute top-3 end-2.5 cursor-pointer" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.8398" cy="16.4141" r="16" fill="#FF4141" />
              <path d="M22.181 11.0708C21.8506 10.7405 21.315 10.7405 20.9846 11.0708L16.8398 15.2157L12.6949 11.0708C12.3646 10.7405 11.8289 10.7405 11.4986 11.0708C11.1682 11.4012 11.1682 11.9368 11.4986 12.2672L15.6434 16.412L11.4986 20.5569C11.1682 20.8873 11.1682 21.4229 11.4986 21.7533C11.8289 22.0836 12.3646 22.0836 12.6949 21.7533L16.8398 17.6084L20.9846 21.7533C21.315 22.0836 21.8506 22.0836 22.181 21.7533C22.5114 21.4229 22.5114 20.8873 22.181 20.5569L18.0361 16.412L22.181 12.2672C22.5114 11.9368 22.5114 11.4012 22.181 11.0708Z" fill="white" />
            </svg>
            <div className='flex flex-col  w-full items-center  p-3   rounded-lg' onMouseDown={(e) => e.stopPropagation()}>

              <p className="text-md text-gray-700 px-1 mb-0.5semibold">Welcome</p>
              {/* <img className="icon icon-cart  my-2 h-10 cursor-pointer hover:text-orange-500" src={logoSvg} alt="Logo" /> */}

              <label className="text-xl text-start text-[#571D0D] font-bold my-2">Sign up Account</label>
              <form onSubmit={handleSubmit} className='w-full mb-2'>
                <div className="flex gap-x-2">
                  <div className="mb-2 w-1/2">
                    <p className="text-md text-gray-700 px-1 mb-0.5">First Name</p>
                    <input
                      type="text"
                      name="fname"
                      value={formData.fname}
                      onChange={handleChange}
                      onKeyDown={handleKeyPress}

                      placeholder="First Name"
                      className="w-full p-3 py-2 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500"
                    />
                    {errors.fname && <span className="  text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">{errors.fname}</span>}

                    {/* {errors.name && <span className="error">{errors.name}</span>} */}
                  </div>
                  <div className="mb-2 w-1/2">
                    <p className="text-md text-gray-700 px-1 mb-0.5">Last Name</p>
                    <input
                      type="text"
                      name="lname"
                      value={formData.lname}
                      onChange={handleChange}
                      onKeyDown={handleKeyPress}

                      placeholder="Last Name"
                      className="w-full p-3 py-2 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500"
                    />
                    {errors.lname && <span className="  text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">{errors.lname}</span>}

                    {/* {errors.name && <span className="error">{errors.name}</span>} */}
                  </div>
                </div>
                <div className="mb-2">
                  <p className="text-md text-gray-700 px-1 mb-0.5">Email</p>
                  <input
                    type="email"

                    name="email"
                    onChange={(e) => handleChange(e)}
                    value={formData.email}

                    // value={formData.email}
                    // onChange={handleChange}
                    onKeyDown={handleKeyPress}

                    placeholder="Email"
                    className={"w-full p-3 py-2 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500" + (errors.email ? ' border-red-400 ' : ' border-gray-300')}
                  />
                  {errors.email && <span className="  text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">{errors.email}</span>}

                </div>
                <div className="mb-2">
                  <p className="text-md text-gray-700 px-1 mb-0.5">Date of Birth</p>
                  <input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}

                    placeholder="DOB"
                    className={"w-full p-3 py-2  text-gray-500 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500" + (errors.dob ? ' border-red-400 ' : ' border-gray-300')}
                  />
                  {errors.dob && <span className="  text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">{errors.dob}</span>}

                  {/* {errors.dob && <span className="error">{errors.date}</span>} */}
                </div>

                <div className="mb-2">
                  <p className="text-md text-gray-700 px-1 mb-0.5">Phone</p>


                  <PhoneInput
                    placeholder="Enter phone number"
                    country={'us'}
                    onlyCountries={['us']}
                    inputStyle={{ ...inputStyle, ...focusStyle }}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    className="w-full border pl-3 border-gray-200 rounded-full focus:outline-none focus:border-orange-500"
                  />

                  {errors.phone && (
                    <span className=" text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">
                      {errors.phone}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <p className="text-md text-gray-700 px-1 mb-0.5">Password</p>
                  <div className='w-full flex justify-between items-center p-3 py-2 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500'>

                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      onKeyDown={handleKeyPress}

                      placeholder="Password"
                      className="w-4/5  focus:outline-none focus:border-whites"
                    />
                    <div
                      onClick={togglePasswordVisibility}
                      className=" items-center cursor-pointer"
                    >
                      {!showPassword ?
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.0007 9.33333C11.4734 9.33333 12.6673 8.13943 12.6673 6.66667C12.6673 5.19391 11.4734 4 10.0007 4C8.52789 4 7.33398 5.19391 7.33398 6.66667C7.33398 8.13943 8.52789 9.33333 10.0007 9.33333Z" fill="#707070" />
                          <path d="M15.7842 1.8825C13.9625 0.633333 12.0129 0 9.99 0C8.16958 0 6.395 0.541666 4.71542 1.60333C3.02167 2.67625 1.36125 4.625 0 6.66667C1.10083 8.5 2.60667 10.385 4.175 11.4658C5.97417 12.705 7.93042 13.3333 9.99 13.3333C12.0317 13.3333 13.9838 12.7054 15.7942 11.4671C17.3879 10.375 18.9046 8.4925 20 6.66667C18.9008 4.85708 17.3792 2.97667 15.7842 1.8825ZM10 10.6667C9.20887 10.6667 8.43552 10.4321 7.77772 9.99254C7.11992 9.55302 6.60723 8.9283 6.30448 8.1974C6.00173 7.4665 5.92252 6.66223 6.07686 5.88631C6.2312 5.11038 6.61216 4.39765 7.17157 3.83824C7.73098 3.27883 8.44372 2.89787 9.21964 2.74353C9.99556 2.58918 10.7998 2.6684 11.5307 2.97115C12.2616 3.2739 12.8864 3.78659 13.3259 4.44439C13.7654 5.10218 14 5.87554 14 6.66667C13.9988 7.72716 13.577 8.74387 12.8271 9.49375C12.0772 10.2436 11.0605 10.6655 10 10.6667Z" fill="#707070" />
                        </svg>

                        :
                        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.1621 3.05951C10.7806 3.01824 10.393 2.99458 10 2.9887C8.28292 2.99632 6.50296 3.41406 4.81812 4.21453C3.56715 4.83334 2.34857 5.70696 1.29028 6.78458C0.770534 7.33468 0.107183 8.1312 0 8.98103C0.0126667 9.7172 0.802551 10.6258 1.29028 11.1775C2.28267 12.2128 3.46952 13.0614 4.81812 13.7476C4.86395 13.7698 4.90995 13.7918 4.95605 13.8135L3.70485 15.9989L5.40494 17.0039L14.5952 1.00032L12.9588 0L11.1621 3.05951ZM15.0427 4.15103L13.7939 6.31572C14.3684 7.06223 14.7095 7.98281 14.7095 8.98103C14.7095 11.4691 12.6008 13.4863 9.99878 13.4863C9.88629 13.4863 9.77728 13.4741 9.66674 13.4667L8.84032 14.8977C9.22146 14.9385 9.60683 14.9682 9.99999 14.9734C11.7187 14.9657 13.4977 14.5431 15.1807 13.7476C16.4316 13.1287 17.6514 12.2551 18.7097 11.1775C19.2295 10.6274 19.8928 9.83089 20 8.98103C19.9873 8.24487 19.1974 7.33623 18.7097 6.78456C17.7173 5.74927 16.5293 4.90066 15.1807 4.21449C15.1351 4.19242 15.0885 4.17257 15.0427 4.15103ZM9.99879 4.47581C10.1129 4.47581 10.2261 4.48038 10.3381 4.48801L9.37012 6.16435C8.01156 6.43983 6.99219 7.59595 6.99219 8.97983C6.99219 9.32746 7.05621 9.66027 7.17407 9.96876C7.17421 9.96911 7.17394 9.96965 7.17407 9.97L6.20361 11.6512C5.62775 10.904 5.28807 9.98042 5.28807 8.98101C5.28809 6.49296 7.39681 4.47579 9.99879 4.47581ZM12.8149 8.01039L10.6336 11.7904C11.9848 11.5097 12.9968 10.3589 12.9968 8.97983C12.9968 8.63853 12.9287 8.31405 12.8149 8.01039Z" fill="#707070" />
                        </svg>

                      }
                    </div>
                  </div>
                  {errors.password && <span className="  text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">{errors.password}</span>}

                  {/* {errors.password && <span className="error">{errors.password}</span>} */}
                </div>
                <div className="mb-2">
                  <p className="text-md text-gray-700 px-1 mb-0.5">Confirm Password</p>

                  <div className='w-full flex justify-between items-center p-3 py-2 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500'>
                    <input
                      type={showPassword ? "text" : "password"}
                      // type="password"
                      name="cpassword"
                      value={formData.cpassword}
                      onChange={handleChange}
                      onKeyDown={handleKeyPress}

                      placeholder="Confirm Password"
                      className="w-4/5  focus:outline-none focus:border-whites"
                    />
                    <div
                      onClick={togglePasswordVisibility}
                      className=" items-center cursor-pointer"
                    >
                      {!showPassword ?
                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.0007 9.33333C11.4734 9.33333 12.6673 8.13943 12.6673 6.66667C12.6673 5.19391 11.4734 4 10.0007 4C8.52789 4 7.33398 5.19391 7.33398 6.66667C7.33398 8.13943 8.52789 9.33333 10.0007 9.33333Z" fill="#707070" />
                          <path d="M15.7842 1.8825C13.9625 0.633333 12.0129 0 9.99 0C8.16958 0 6.395 0.541666 4.71542 1.60333C3.02167 2.67625 1.36125 4.625 0 6.66667C1.10083 8.5 2.60667 10.385 4.175 11.4658C5.97417 12.705 7.93042 13.3333 9.99 13.3333C12.0317 13.3333 13.9838 12.7054 15.7942 11.4671C17.3879 10.375 18.9046 8.4925 20 6.66667C18.9008 4.85708 17.3792 2.97667 15.7842 1.8825ZM10 10.6667C9.20887 10.6667 8.43552 10.4321 7.77772 9.99254C7.11992 9.55302 6.60723 8.9283 6.30448 8.1974C6.00173 7.4665 5.92252 6.66223 6.07686 5.88631C6.2312 5.11038 6.61216 4.39765 7.17157 3.83824C7.73098 3.27883 8.44372 2.89787 9.21964 2.74353C9.99556 2.58918 10.7998 2.6684 11.5307 2.97115C12.2616 3.2739 12.8864 3.78659 13.3259 4.44439C13.7654 5.10218 14 5.87554 14 6.66667C13.9988 7.72716 13.577 8.74387 12.8271 9.49375C12.0772 10.2436 11.0605 10.6655 10 10.6667Z" fill="#707070" />
                        </svg>
                        :
                        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.1621 3.05951C10.7806 3.01824 10.393 2.99458 10 2.9887C8.28292 2.99632 6.50296 3.41406 4.81812 4.21453C3.56715 4.83334 2.34857 5.70696 1.29028 6.78458C0.770534 7.33468 0.107183 8.1312 0 8.98103C0.0126667 9.7172 0.802551 10.6258 1.29028 11.1775C2.28267 12.2128 3.46952 13.0614 4.81812 13.7476C4.86395 13.7698 4.90995 13.7918 4.95605 13.8135L3.70485 15.9989L5.40494 17.0039L14.5952 1.00032L12.9588 0L11.1621 3.05951ZM15.0427 4.15103L13.7939 6.31572C14.3684 7.06223 14.7095 7.98281 14.7095 8.98103C14.7095 11.4691 12.6008 13.4863 9.99878 13.4863C9.88629 13.4863 9.77728 13.4741 9.66674 13.4667L8.84032 14.8977C9.22146 14.9385 9.60683 14.9682 9.99999 14.9734C11.7187 14.9657 13.4977 14.5431 15.1807 13.7476C16.4316 13.1287 17.6514 12.2551 18.7097 11.1775C19.2295 10.6274 19.8928 9.83089 20 8.98103C19.9873 8.24487 19.1974 7.33623 18.7097 6.78456C17.7173 5.74927 16.5293 4.90066 15.1807 4.21449C15.1351 4.19242 15.0885 4.17257 15.0427 4.15103ZM9.99879 4.47581C10.1129 4.47581 10.2261 4.48038 10.3381 4.48801L9.37012 6.16435C8.01156 6.43983 6.99219 7.59595 6.99219 8.97983C6.99219 9.32746 7.05621 9.66027 7.17407 9.96876C7.17421 9.96911 7.17394 9.96965 7.17407 9.97L6.20361 11.6512C5.62775 10.904 5.28807 9.98042 5.28807 8.98101C5.28809 6.49296 7.39681 4.47579 9.99879 4.47581ZM12.8149 8.01039L10.6336 11.7904C11.9848 11.5097 12.9968 10.3589 12.9968 8.97983C12.9968 8.63853 12.9287 8.31405 12.8149 8.01039Z" fill="#707070" />
                        </svg>
                      }
                    </div>
                  </div>
                  {errors.cpassword && <span className="  text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5  ">{errors.cpassword}</span>}

                  {/* {errors.cpassword && <span className="error">{errors.cpassword}</span>} */}
                </div>
                <button
                  className="w-full bg-orange-600 text-white py-3 rounded-full hover-bg-blue-600 focus:outline-none"
                  type="submit">Sign Up</button>
              </form>

              <label className='text-gray-600  font-semibold  '>Already have account?
                <span className="text-md font-bold  text-center px-2 "
                  //  onClick={() => navigate('/login')}
                  onClick={() => setPopHandel(1)} >Sign In</span>
              </label>
              {/* <GoogleLogin onSuccess={handleGoogleSuccess} onError={() => console.log('Google login failed')} /> */}
              {/* <FacebookLogin
                appId="YOUR_FACEBOOK_APP_ID"
                fields="name,email,picture"
                callback={handleFacebookSuccess}
                onFailure={(response) => console.log('Facebook login failed', response)}
              /> */}
            </div>
          </div>
        </div>
      </div >
    </div >

  );
};

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
