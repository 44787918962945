import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import logoSvg from '../src/Assets/logo.svg';
// import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useAppContext } from './AppContextProvider';

export const Login = ({ ApiCall_GET, sendData }) => {

  const navigate = useNavigate();
  const userSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email Format!").required("Email Is Required!"),
    password: Yup.string()
      .required('Password Is Required!'),

  });
  const { selectedAdress, setSelectedAdress } = useAppContext()
  const [step, setStep] = useState(1);
  const [forgetpass, setForget] = useState(false)
  const [loginBy, setLoginBy] = useState(true);
  const [otpSent, setOtpSent] = useState(true);
  const [formData, setFormData] = useState({});
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeLogin = (e) => {

    const { name, value } = e.target
    setFormData({
      ...loginData,
      [name]: value
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value
    });
  };
  const validate = () => {
    let tempErrors = {};
    tempErrors.email = formData.email ? "" : "Email is required.";
    tempErrors.password = formData.password ? "" : "Password is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };


  const handleSubmit = (e) => {
    setSelectedAdress('')
    e.preventDefault();
    if (validate()) {
      axios.post('https://api.vapedelivery.com/api/auth/login', formData, {
        withCredentials: false
      }).then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Login Successfull',
          showConfirmButton: false,
          timer: 3000
        })
        const { token, userid, expiration } = response.data.userData;
        localStorage.setItem('token', token);
        localStorage.setItem('userid', userid);
        localStorage.setItem('expiration', expiration);
        navigate('/product');
        window.location.reload();
      })
        .catch(error => {
          if (error.response && error.response.data) {
            // Assuming error.response.data contains the error message directly
            setErrors2(error.response.data);
          }
        });
    }
  };
  // -----------------------
  const handleRequestOtp = (e) => {
    e.preventDefault();

    axios.post('https://api.vapedelivery.com/api/auth/login/code-req', { phone: formData.phone }, { withCredentials: false })
      .then(response => {
        // alert('OTP code sent to your phone number');
        Swal.fire({
          icon: 'success',
          title: 'OTP code sent to your phone number',
          showConfirmButton: false,
          timer: 3000
        })
        setOtpSent(false); // set to true to indicate the OTP was sent successfully
        // window.location.reload();
      })
      .catch(error => {
        console.error('Error requesting OTP code:', error);
        if (error.response && error.response.data) {
          setErrors({ api: error.response.data });
        }
      });
  };
  const handleVerifyPhoneLogin = (e) => {
    e.preventDefault();
    axios.post('https://api.vapedelivery.com/api/auth/login/phone', { phone: formData.phone, code: formData.code }, { withCredentials: false, })
      .then(response => {
        // alert('Phone number verified and logged in successfully');
        Swal.fire({
          icon: 'success',
          title: 'Phone number verified and logged in successfully',
          showConfirmButton: false,
          timer: 3000
        })

        const { token, userid } = response.data.userData;
        localStorage.setItem('token', token);
        localStorage.setItem('userid', userid);
        navigate('/product', localStorage);
        window.location.reload();
      })
      .catch(error => {
        console.error('Error verifying phone number:', error);
        if (error.response && error.response.data) {
          setErrors({ api: error.response.data });
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  // Reset pass ===================================
  const handleReset = (e) => {
    e.preventDefault();
    // Handle form submission logic, e.g., send email to backend

    axios.post('https://api.vapedelivery.com/api/auth/reset-request', { email: formData.email }, {
      withCredentials: false
    }).then(response => {
      // alert(`Password reset Code sent to Phone`);
      Swal.fire({
        icon: 'success',
        title: 'Password reset Code sent successfully.',
        showConfirmButton: false,
        timer: 3000
      })

      setStep(2);
    })
      .catch(error => {
        console.error('Error fetching data:', error);
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.error || 'An error occurred',
          showConfirmButton: false,
          timer: 3000
        });
        if (error.response && error.response.data) {
          setErrors({ api: error.response.data });
        }
      });

  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    axios.post('https://api.vapedelivery.com/api/auth/verify', { email: formData.email, otpcode: formData.otpcode }, { withCredentials: false })
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'OTP verified successfully',
          showConfirmButton: false,
          timer: 3000
        })
        setFormData({ ...formData, token: response.data.token });
        setStep(3);
        // set to true to indicate the OTP was sent successfully
      })
      .catch(error => {
        console.error('Error requesting OTP code:', error);
        if (error.response && error.response.data) {
          setErrors({ api: error.response.data });
        }
      });
  };
  const handleResetPassword = (e) => {
    e.preventDefault();
    axios.post('https://api.vapedelivery.com/api/auth/reset', { password: formData.password, token: formData.token }, { withCredentials: false })
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Password reset successfully',
          showConfirmButton: false,
          timer: 3000
        })
        setStep(1);
        setForget(false)
        setFormData({ email: '', otpcode: '', password: '' });
      })
      .catch(error => {


        if (error.response && error.response.data) {
          setErrors({ api: error.response.data });
        }
      });
  };


  // useEffect(() => {

  //   // Initialize the Facebook SDK
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: '22f2752e4314fac2bef7788496a2e0c0',
  //       cookie: true,
  //       xfbml: true,
  //       version: 'v12.0'
  //     });
  //   };

  //   // Load the SDK asynchronously
  //   (function (d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) { return; }
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));

  // }, []);

  // const handleFacebookLogin = () => {
  //   window.FB.login(function (response) {
  //     if (response.authResponse) {
  //        //console.log('Welcome! Fetching your information.... ');
  //       window.FB.api('/me', function (response) {
  //          //console.log('Good to see you, ' + response.name + '.');
  //       });
  //     } else {
  //        //console.log('User cancelled login or did not fully authorize.');
  //     }
  //   });
  // };

  const handleFacebookResponse = (response) => {
    //console.log('Facebook login response:', response);
    // Handle the response here
  };


  const handleGoogleSuccess = (response) => {
    const { credential } = response;
    // Extract JWT token from response
    // Send the JWT token to your backend for verification
    axios.post('https://api.vapedelivery.com/api/auth/google-login', { tokenId: credential }, { withCredentials: false })
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Login Successfull',
          showConfirmButton: false,
          timer: 3000
        })
        const { token, userid } = response.data.userData;
        localStorage.setItem('token', token);
        localStorage.setItem('userid', userid);
        navigate('/product');
        window.location.reload();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        Swal.fire({
          icon: 'alert',
          title: error,
          showConfirmButton: false,
          timer: 3000
        })

        if (error.response && error.response.data) {
          console.error('API Error:', error.response.data);
        }
      });
  };


  const handleGoogleFailure = (error) => {
    console.error('Google login error:', error);
  };

  const getGoogleUser = async (tokenId) => {
    try {
      const response = await fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${tokenId}`);
      const googleUser = await response.json();
      return googleUser;
    } catch (error) {
      console.error('Error fetching Google user data:', error);
      throw error;
    }
  };
  const inputStyle = {
    width: '100%',
    // padding: '0.75rem',
    border: '1px solid #E5E7EB', // border-gray-200
    borderRadius: '9999px', // rounded-full
    outline: 'none',
    paddingTop: '20px ',
    paddingBottom: '20px ',
    transition: 'border-color 0.2s ease-in-out' // Optional: smooth transition for border color change
  };
  const focusStyle = isFocused ? { borderColor: '#FB923C' } : {}; // focus:border-orange-500
  const { popHandel, setPopHandel } = useAppContext()
  return (
    <>
      <div className="fixed z-50 inset-0 overflow-y-auto " >
        <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
            <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
          </div>
          {/* <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span> */}
          {/* <Draggable> */}
          <div className={`sm:my-8   md:w-1/3 sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
            role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
              <svg onClick={() => setPopHandel(0)} className="absolute top-3 end-2.5 cursor-pointer" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16.8398" cy="16.4141" r="16" fill="#FF4141" />
                <path d="M22.181 11.0708C21.8506 10.7405 21.315 10.7405 20.9846 11.0708L16.8398 15.2157L12.6949 11.0708C12.3646 10.7405 11.8289 10.7405 11.4986 11.0708C11.1682 11.4012 11.1682 11.9368 11.4986 12.2672L15.6434 16.412L11.4986 20.5569C11.1682 20.8873 11.1682 21.4229 11.4986 21.7533C11.8289 22.0836 12.3646 22.0836 12.6949 21.7533L16.8398 17.6084L20.9846 21.7533C21.315 22.0836 21.8506 22.0836 22.181 21.7533C22.5114 21.4229 22.5114 20.8873 22.181 20.5569L18.0361 16.412L22.181 12.2672C22.5114 11.9368 22.5114 11.4012 22.181 11.0708Z" fill="white" />
              </svg>
              <div className='flex flex-col my-3 w-full items-center justify-center p-3 mt-0  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>
                <p className="text-md text-gray-700 font-semibold">Welcome</p>
                {/* <img className="icon icon-cart  my-3 h-10 cursor-pointer hover:text-orange-500" src={logoSvg} alt="Logo" /> */}
                {!forgetpass ?
                  <>

                    <label className="text-xl text-start text-[#571D0D] font-bold my-3">Login Account</label>
                    {loginBy ? (<>
                      {/* <form onSubmit={handleSubmit} className='w-4/5'>

                        <div className="mb-2 w-full">
                          <p className="text-md text-gray-700 font-">Email</p>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="w-full p-3 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500 "
                            style={{  fontFamily: 'Roboto,sans-serif' }}
                            value={formData.email}
                            onChange={(e) => handleChangeLogin(e)}

                            autoFocus={true}
                          />
                          {errors.email && <span className="bg-red-100   text-red-800 text-sm font-medium  text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errors.email}</span>}

                        </div>
                       
                      <div className="mb-2 w-full">
                        <p className="text-md text-gray-700 font-">Password</p>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="w-full p-3 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500 "
                          style={{  fontFamily: 'Roboto,sans-serif' }}
                          value={formData.password}
                          onChange={(e) => handleChangeLogin(e)}
                          onKeyDown={handleKeyPress}
                          autoFocus={true}
                        />
                        {errors.password && <span className="bg-red-100   text-red-800 text-sm font-medium  text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">{errors.password}</span>}

                      
                      </div>
                      {errors.api && <span className="error">{errors.api.message}</span>}
                      <p onClick={() => setForget(true)} className="text-right pb-2 px-3 text-gray-700 rounded mb-4  md:border-0   font-medium  text-blue-500  cursor-pointer ">Forget Pasword?</p>
                      <button className="w-full mb-3 bg-orange-600 rounded-full text-white py-3 rounded hover-bg-blue-600 focus:outline-none"
                        type="submit">Login</button>
                      <div className='flex justify-center items-center gap-2 mt-3 '>
                        <div className='w-1/2  h-[1px] bg-gray-400 '></div> or
                        <div className='w-1/2  h-[1px] bg-gray-400 '></div>
                      </div>
                      <div onClick={() => setLoginBy(false)} className="w-full gap-x-3 flex justify-center items-center my-3 bg-white border rounded-full text-white py-2 rounded hover-bg-blue-600 focus:outline-none">
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M29.3249 22.7949C29.102 24.4887 28.2701 26.0434 26.9847 27.1687C25.6993 28.294 24.0482 28.913 22.3399 28.9099C12.4149 28.9099 4.33986 20.8349 4.33986 10.9099C4.33679 9.20152 4.95571 7.55043 6.08102 6.26502C7.20633 4.97961 8.76107 4.14779 10.4549 3.9249C10.8832 3.8726 11.3169 3.96023 11.6913 4.1747C12.0658 4.38917 12.3608 4.71898 12.5324 5.1149L15.1724 11.0087V11.0237C15.3037 11.3267 15.358 11.6576 15.3303 11.9867C15.3026 12.3159 15.1938 12.6331 15.0136 12.9099C14.9911 12.9437 14.9674 12.9749 14.9424 13.0062L12.3399 16.0912C13.2761 17.9937 15.2661 19.9662 17.1936 20.9049L20.2361 18.3162C20.266 18.291 20.2973 18.2677 20.3299 18.2462C20.6065 18.0617 20.9247 17.949 21.2558 17.9185C21.5869 17.8879 21.9204 17.9404 22.2261 18.0712L22.2424 18.0787L28.1311 20.7174C28.5277 20.8884 28.8583 21.1831 29.0735 21.5576C29.2887 21.9321 29.3769 22.3661 29.3249 22.7949Z" fill="#4D5364" />
                        </svg>

                        <button className={"text-gray-600 font-semibold "} >Sign In with Phone Number</button>
                      </div>
 <button className={'p-2 rounded bg-gray-200 rounded'} onClick={() => setLoginBy(false)}>Login with Phone</button> 
                    </form> */}
                      <form onSubmit={handleSubmit} className='w-4/5'>
                        <div className="mb-2 w-full">
                          <p className="text-md text-gray-700 mb-0.5 px-1">Email</p>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="w-full p-3 border bg-white border-gray-200 rounded-full focus:outline-none focus:border-orange-500"
                            style={{ fontFamily: 'Roboto, sans-serif' }}
                            value={formData.email}
                            onChange={handleChange}
                            autoFocus
                          />
                          {errors.email && <span className="error    text-red-800 text-sm font-medium ">{errors.email}</span>}

                        </div>
                        <div className="mb-2 w-full">
                          <p className="text-md text-gray-700 mb-0.5 px-1">Password</p>
                          <div className='w-full flex justify-between items-center p-3 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500'>

                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                              style={{ fontFamily: 'Roboto, sans-serif' }}
                              value={formData.password}
                              onChange={handleChange}
                              className="w-4/5  focus:outline-none focus:border-whites"
                            />
                            <div onClick={togglePasswordVisibility} className=" items-center cursor-pointer">
                              {!showPassword ?
                                <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.0007 9.33333C11.4734 9.33333 12.6673 8.13943 12.6673 6.66667C12.6673 5.19391 11.4734 4 10.0007 4C8.52789 4 7.33398 5.19391 7.33398 6.66667C7.33398 8.13943 8.52789 9.33333 10.0007 9.33333Z" fill="#707070" />
                                  <path d="M15.7842 1.8825C13.9625 0.633333 12.0129 0 9.99 0C8.16958 0 6.395 0.541666 4.71542 1.60333C3.02167 2.67625 1.36125 4.625 0 6.66667C1.10083 8.5 2.60667 10.385 4.175 11.4658C5.97417 12.705 7.93042 13.3333 9.99 13.3333C12.0317 13.3333 13.9838 12.7054 15.7942 11.4671C17.3879 10.375 18.9046 8.4925 20 6.66667C18.9008 4.85708 17.3792 2.97667 15.7842 1.8825ZM10 10.6667C9.20887 10.6667 8.43552 10.4321 7.77772 9.99254C7.11992 9.55302 6.60723 8.9283 6.30448 8.1974C6.00173 7.4665 5.92252 6.66223 6.07686 5.88631C6.2312 5.11038 6.61216 4.39765 7.17157 3.83824C7.73098 3.27883 8.44372 2.89787 9.21964 2.74353C9.99556 2.58918 10.7998 2.6684 11.5307 2.97115C12.2616 3.2739 12.8864 3.78659 13.3259 4.44439C13.7654 5.10218 14 5.87554 14 6.66667C13.9988 7.72716 13.577 8.74387 12.8271 9.49375C12.0772 10.2436 11.0605 10.6655 10 10.6667Z" fill="#707070" />
                                </svg>
                                :
                                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.1621 3.05951C10.7806 3.01824 10.393 2.99458 10 2.9887C8.28292 2.99632 6.50296 3.41406 4.81812 4.21453C3.56715 4.83334 2.34857 5.70696 1.29028 6.78458C0.770534 7.33468 0.107183 8.1312 0 8.98103C0.0126667 9.7172 0.802551 10.6258 1.29028 11.1775C2.28267 12.2128 3.46952 13.0614 4.81812 13.7476C4.86395 13.7698 4.90995 13.7918 4.95605 13.8135L3.70485 15.9989L5.40494 17.0039L14.5952 1.00032L12.9588 0L11.1621 3.05951ZM15.0427 4.15103L13.7939 6.31572C14.3684 7.06223 14.7095 7.98281 14.7095 8.98103C14.7095 11.4691 12.6008 13.4863 9.99878 13.4863C9.88629 13.4863 9.77728 13.4741 9.66674 13.4667L8.84032 14.8977C9.22146 14.9385 9.60683 14.9682 9.99999 14.9734C11.7187 14.9657 13.4977 14.5431 15.1807 13.7476C16.4316 13.1287 17.6514 12.2551 18.7097 11.1775C19.2295 10.6274 19.8928 9.83089 20 8.98103C19.9873 8.24487 19.1974 7.33623 18.7097 6.78456C17.7173 5.74927 16.5293 4.90066 15.1807 4.21449C15.1351 4.19242 15.0885 4.17257 15.0427 4.15103ZM9.99879 4.47581C10.1129 4.47581 10.2261 4.48038 10.3381 4.48801L9.37012 6.16435C8.01156 6.43983 6.99219 7.59595 6.99219 8.97983C6.99219 9.32746 7.05621 9.66027 7.17407 9.96876C7.17421 9.96911 7.17394 9.96965 7.17407 9.97L6.20361 11.6512C5.62775 10.904 5.28807 9.98042 5.28807 8.98101C5.28809 6.49296 7.39681 4.47579 9.99879 4.47581ZM12.8149 8.01039L10.6336 11.7904C11.9848 11.5097 12.9968 10.3589 12.9968 8.97983C12.9968 8.63853 12.9287 8.31405 12.8149 8.01039Z" fill="#707070" />
                                </svg>
                              }
                            </div>
                          </div>
                          {errors.password && <span className="error    text-red-800 text-sm font-medium ">{errors.password}</span>}
                        </div>
                        {/* {errors.api && <span className="error">{errors.api}</span>} */}
                        <p onClick={() => { setForget(true); setFormData({}) }} className="text-right pb-2 px-3 text-gray-700 rounded mb-4 font-medium text-blue-500 cursor-pointer">Forgot Password?</p>
                        <button className="w-full mb-3 bg-orange-600 rounded-full text-white py-3 hover-bg-blue-600 focus:outline-none" type="submit">Login</button>
                        {errors2 && <div className="flex items-center p-4 mb-4 text-sm   text-red-800 text-sm font-medium  rounded-lg bg-red-50 border" role="alert">
                          <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className="sr-only">Info</span>
                          <div>
                            <span className="font-medium">Login failed</span> Incorrect Email/pass
                          </div>
                        </div>}
                        <div className='flex justify-center items-center gap-2 mt-3 mb-3'>
                          <div className='w-1/2 h-[1px] bg-gray-400'></div> or
                          <div className='w-1/2 h-[1px] bg-gray-400'></div>
                        </div>
                        {/* <div onClick={() => setLoginBy(false)} className="w-full gap-x-3 flex justify-center items-center my-3 bg-white border rounded-full text-white py-2 hover-bg-blue-600 focus:outline-none">
                          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.3249 22.7949C29.102 24.4887 28.2701 26.0434 26.9847 27.1687C25.6993 28.294 24.0482 28.913 22.3399 28.9099C12.4149 28.9099 4.33986 20.8349 4.33986 10.9099C4.33679 9.20152 4.95571 7.55043 6.08102 6.26502C7.20633 4.97961 8.76107 4.14779 10.4549 3.9249C10.8832 3.8726 11.3169 3.96023 11.6913 4.1747C12.0658 4.38917 12.3608 4.71898 12.5324 5.1149L15.1724 11.0087V11.0237C15.3037 11.3267 15.358 11.6576 15.3303 11.9867C15.3026 12.3159 15.1938 12.6331 15.0136 12.9099C14.9911 12.9437 14.9674 12.9749 14.9424 13.0062L12.3399 16.0912C13.2761 17.9937 15.2661 19.9662 17.1936 20.9049L20.2361 18.3162C20.266 18.291 20.2973 18.2677 20.3299 18.2462C20.6065 18.0617 20.9247 17.949 21.2558 17.9185C21.5869 17.8879 21.9204 17.9404 22.2261 18.0712L22.2424 18.0787L28.1311 20.7174C28.5277 20.8884 28.8583 21.1831 29.0735 21.5576C29.2887 21.9321 29.3769 22.3661 29.3249 22.7949Z" fill="#4D5364" />
                          </svg>
                          <button className="text-gray-600 font-semibold">Sign In with Phone Number</button>
                        </div> */}
                      </form>

                    </>)
                      :
                      <>
                        {otpSent ? <form onSubmit={handleRequestOtp}>

                          <PhoneInput
                            placeholder="Enter phone number"
                            country={'us'}
                            // preferredCountries={['us']}
                            inputStyle={{ ...inputStyle, ...focusStyle }}
                            onlyCountries={['us']}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            className="w-full pl-3 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500"
                          />
                          {errors.api && <span className="error text-red-800 text-sm font-medium ">{errors.api.message}</span>}

                          <button className="w-full my-3 bg-orange-600 rounded-full text-white py-3 rounded hover-bg-blue-600 focus:outline-none"
                            type="submit">Request OTP</button>
                          <div className='flex justify-center items-center gap-2 mt-3 '>
                            <div className='w-1/2  h-[1px] bg-gray-400 '></div> or
                            <div className='w-1/2  h-[1px] bg-gray-400 '></div>
                          </div>
                          <div onClick={() => setLoginBy(true)} className="w-full gap-x-3 flex justify-center items-center my-3 bg-white border rounded-full text-white py-2 rounded hover-bg-blue-600 focus:outline-none">
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M29.3249 22.7949C29.102 24.4887 28.2701 26.0434 26.9847 27.1687C25.6993 28.294 24.0482 28.913 22.3399 28.9099C12.4149 28.9099 4.33986 20.8349 4.33986 10.9099C4.33679 9.20152 4.95571 7.55043 6.08102 6.26502C7.20633 4.97961 8.76107 4.14779 10.4549 3.9249C10.8832 3.8726 11.3169 3.96023 11.6913 4.1747C12.0658 4.38917 12.3608 4.71898 12.5324 5.1149L15.1724 11.0087V11.0237C15.3037 11.3267 15.358 11.6576 15.3303 11.9867C15.3026 12.3159 15.1938 12.6331 15.0136 12.9099C14.9911 12.9437 14.9674 12.9749 14.9424 13.0062L12.3399 16.0912C13.2761 17.9937 15.2661 19.9662 17.1936 20.9049L20.2361 18.3162C20.266 18.291 20.2973 18.2677 20.3299 18.2462C20.6065 18.0617 20.9247 17.949 21.2558 17.9185C21.5869 17.8879 21.9204 17.9404 22.2261 18.0712L22.2424 18.0787L28.1311 20.7174C28.5277 20.8884 28.8583 21.1831 29.0735 21.5576C29.2887 21.9321 29.3769 22.3661 29.3249 22.7949Z" fill="#4D5364" />
                            </svg>

                            <button className={"text-gray-600 font-semibold "} >Login with Email</button>
                          </div>
                          {/* <button type="submit" className="p-3 bg-orange-500 text-white rounded-full">Request OTP</button> */}
                        </form>
                          : (
                            <form onSubmit={handleVerifyPhoneLogin}>
                              <p>.</p>
                              <input
                                type="text"
                                name="code"
                                placeholder="OTP Code"
                                className="w-full p-3 border border-gray-200 rounded-full focus:outline-none focus:border-orange-500 "
                                style={{ fontFamily: 'Roboto,sans-serif' }}
                                value={formData.code}
                                onChange={handleChange}
                              />
                              {errors.api && <span className="error">{errors.api.message}</span>}
                              <button className="w-full my-3 bg-orange-600 rounded-full text-white py-3 rounded hover-bg-blue-600 focus:outline-none"
                                type="submit">Verify and Login</button>


                              {/* <button type="submit" className="p-3 bg-orange-500 text-white rounded-full">Verify and Login</button> */}
                            </form>
                          )
                        }
                      </>
                    }
                    {/* <div className='my-3'>
                      <GoogleOAuthProvider clientId="500525555801-l02besec3lhi04751hjbcek3eivmql61.apps.googleusercontent.com">
                        <GoogleLogin
                          onSuccess={handleGoogleSuccess}
                          onFailure={handleGoogleFailure}
                          isSignedIn={true}
                          buttonText="Login with Google"
                        />
                      </GoogleOAuthProvider>
                    </div> */}
                    <label className='text-gray-600  mt-2 font-semibold  '>Don't have an account?
                      <span className="text-md font-bold  text-center px-2 "
                        // onClick={() => navigate('/signup')}
                        onClick={() => setPopHandel(2)}
                      >Sign Up</span>
                    </label>
                  </>
                  :
                  <>
                    <label className="text-xl text-center text-[#571D0D] font-bold my-3">Forgot Password</label>
                    {step === 1 && (
                      <form onSubmit={handleReset} className='w-4/5'>
                        <div className="my-2">
                          <input type="text" name="email" placeholder="Email" className="w-full p-3 border border-gray-300 rounded-full focus:outline-none focus:border-orange-500 "
                            value={formData.email} onChange={(e) => handleChange(e)} autoFocus={true}
                          // onKeyDown={handleKeyPress}
                          />
                          {errors.email && <span className="error    text-red-800 text-sm font-medium ">{errors.email}</span>}
                        </div>
                        {errors.api && <span className="error    text-red-800 text-sm font-medium ">{errors.api.message}</span>}
                        <button className="w-full bg-orange-600 text-white py-3 rounded-full  focus:outline-none"
                          type="submit">submit</button>
                      </form>
                    )}
                    {step === 2 && (
                      <form onSubmit={handleVerifyOtp} className='w-4/5'>
                        <div className="my-2">
                          <input
                            type="text"
                            name="otpcode"
                            placeholder="OTP Code"
                            className="w-full p-3 border border-gray-300 rounded-full focus:outline-none focus:border-orange-500"
                            value={formData.otpcode}
                            onChange={handleChange}
                          />
                        </div>
                        {errors.api && <span className="error text-red-800 text-sm font-medium ">{errors?.api?.error}</span>}
                        <button className="w-full bg-orange-600 text-white py-3 mt-1 rounded-full focus:outline-none" type="submit">Verify</button>
                      </form>
                    )}
                    {step === 3 && (
                      <form onSubmit={handleResetPassword} className='w-4/5'>
                        <div className="my-2">
                          <input
                            type="password"
                            name="password"
                            placeholder="New Password"
                            className="w-full p-3 border border-gray-300 rounded-full focus:outline-none focus:border-orange-500"
                            value={formData.password}
                            onChange={handleChange}
                            autoFocus
                          />
                        </div>
                        {errors.api && <span className="error     text-red-800 text-sm font-medium ">{errors.api.message}</span>}
                        <button className="w-full bg-orange-600 text-white py-3 rounded-full focus:outline-none" type="submit">
                          Reset Password
                        </button>
                      </form>
                    )}
                  </>
                }

                {/* <FacebookLogin
                  appId="22f2752e4314fac2bef7788496a2e0c0"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookResponse}
                  textButton="Login with Facebook"
                /> */}

                {/* <FacebookLogin
                  appId="22f2752e4314fac2bef7788496a2e0c0"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookLogin}
                  render={renderProps => (
                    <button onClick={renderProps.onClick}>Login with Facebook</button>
                  )}
                /> */}

              </div>
            </div >
          </div >
        </div >
      </div >
    </>
  );
};

const mapStateToProps = (state) => ({
  // patient: state.patientReducer.patient,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
