import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function THCADisclaimer() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className=" text-start max-w-screen-xl">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4 ">THCA Disclaimer</h1>
                <p className="mb-4">
                    All products containing THCA will not ship to states in which it is illegal.
                    This policy will be rigorously adhered to by any and all companies and websites under Vapedelivery.com
                    IS THCA FLOWER LEGAL UNDER UNITED STATES FEDERAL LAW?
                    It’s important to note that THCA products are completely legal under federal law, meaning there are no limitations on how much you can buy or possess. This is because the Farm Bill of 2018 legalized all hemp derivatives containing up to 0.3% delta-9 THC – a threshold which THCA flower comfortably falls within.

                </p>
                <p className="mb-4">
                    While it may be true that heating converts THCA into delta-9 THC, this doesn’t change its classification as a separate cannabinoid altogether. So if you’re looking for an effective and hassle-free way to enjoy your favorite cannabis strains without any legal concerns, then look no further than high-quality THCA products!
                </p>

                <p className="mb-4">
                    IS THCA FLOWER LEGAL IN EVERY STATE?
                    Although THCA flower is permitted by federal law, it’s important to note that not all states have legalized its use. In fact, certain regions prohibit products containing more than 0.3% “total THC,” which encompasses both THCA and delta 9 components. It’s crucial to stay informed about the legal status of these products in your state before making any purchases or consuming them for personal use.
                </p>
                <p className="mb-4">
                    Alabama: THCA flower products are completely lawful, with no restrictions on the variety of items available for sale. Furthermore, there is no limit to how much one can purchase or hold at any given time. As long as you’re an adult, acquiring THCA flower goods is a breeze. Additionally, Alabama has legalized medical marijuana for eligible patients and reduced penalties for possessing small amounts of cannabis in recent years – indicating that society’s attitude towards this plant is shifting positively!
                </p>
                <p className="mb-4">
                    Alaska: It’s imperative to note that THCA flower products are currently illegal, making it unlawful for businesses to sell them. Additionally, online retailers are prohibited from delivering these items straight to your doorstep. However, it is crucial to acknowledge that the state has legalized both medical and recreational cannabis usage despite this restriction on THCA flower products.
                </p>
                <p className="mb-4">
                    Arizona: THCA flower products are completely legal and there is no restriction on the types of products that can be sold. Moreover, you can purchase or possess as much THCA flower product as you want without any limitations. As long as you’re an adult, buying these products won’t pose a problem for anyone in Arizona.

                </p>
                <p className="mb-4">
                    However, it’s worth noting that both recreational and medical marijuana sales are allowed in Arizona too! So if either of those options interests you more than THCA flowers do – feel free to explore them further with ease knowing they’re all above board here.

                </p>
                <p className="mb-4">
                    Arkansas: In Arkansas, THCA flower products are completely legal and there is no restriction on the types of products that can be sold. Additionally, you can purchase or possess as much as you want without any limitations whatsoever. As long as you’re an adult, purchasing these items won’t pose a problem for you at all! However, it’s important to note that recreational marijuana isn’t allowed in this state but they do have a medical program available for those who qualify.

                </p>
                <p className="mb-4">
                    It should also be noted that Arizona has different laws regarding cannabis than Arkansas does – both recreational and medicinal use are permitted in their state.

                </p>
                <p className="mb-4">
                    California: THCA flower products are completely legal and there is no restriction on the variety of items that can be marketed. Additionally, you have complete freedom when it comes to purchasing or owning these goods. As long as you’re an adult, THCA flower products can easily be bought without any hassle whatsoever! Moreover, marijuana has been legalized for both medicinal and recreational purposes making it a great state to consume cannabinoids.

                </p>
                <p className="mb-4">
                    Colorado: THCA flower products are completely within the bounds of legality. The range of available options is vast and unrestricted, with no limitations on what can be sold or how much one may purchase or possess. As long as you’re an adult, acquiring THCA flower products poses no legal challenge whatsoever. Furthermore, marijuana has been legalized for both recreational and medicinal purposes alike!

                </p>
                <p className="mb-4">
                    Connecticut: THCA flower products are completely legal and there is an extensive range of options available for purchase without any restrictions. The amount you can buy or possess also has no limitations, making it a hassle-free experience to enjoy these fantastic products. As long as you’re over the age of 18, purchasing THCA flower items is permitted by law in most states that allow both recreational and medical marijuana sales. So don’t hesitate to indulge yourself with this incredible product!

                </p>
                <p className="mb-4">
                    Delaware: THCA flower products are completely lawful and unrestricted in terms of their sale. There is no limit to the variety or quantity that can be bought or owned, as long as you are an adult. It’s important to note that while recreational marijuana remains illegal, small amounts of possession have been decriminalized by the state, with a medical program available for eligible patients. So if you’re looking for high-quality THCA flower products without any legal hassles whatsoever – look no further!

                </p>
                <p className="mb-4">
                    Florida: THCA flower products are completely legal and there is no restriction on the variety of items that can be sold. Furthermore, you have complete freedom when it comes to purchasing or owning these products without any limitations whatsoever! As long as you’re an adult, THCA flower goods can easily be purchased at your convenience. The state also provides legally approved medical marijuana for eligible patients; however, recreational use remains strictly prohibited.

                </p>
                <p className="mb-4">
                    Georgia: THCA flower products are completely lawful, with no restrictions on the variety of items that can be marketed. Additionally, there is no limit to how much one may purchase or possess. As long as you’re an adult, THCA flower goods are available for purchase without any legal repercussions. While recreational marijuana remains prohibited in this state, medical marijuana can still be obtained by eligible patients who require it for medicinal purposes.

                </p>
                <p className="mb-4">
                Hawaii: It’s important to note that THCA flower products are currently deemed illegal, which implies that businesses cannot stock them. Additionally, online vendors aren’t authorized to deliver these items straight to your doorstep. Although the state has decriminalized small amounts of marijuana and maintains a medical program for it, recreational use is still not allowed.

                </p>
                <p className="mb-4">
                Idaho: It is imperative to note that THCA flower products are deemed unlawful, thereby rendering it illegal for businesses to stock them. Furthermore, online retailers are prohibited from delivering these products straight to your doorstep. To add on this, the state has put in place strict measures against any hemp product containing more than 0% THC; thus both recreational and medical usage of such items have been banned entirely.

                </p> <p className="mb-4">
                Illinois: THCA flower products are completely legal, with no restrictions on the types of goods that can be sold or purchased. Whether for recreational or medicinal use, there is no limit to how much you may possess as long as you’re an adult. The state fully permits marijuana sales and consumption in all forms – so why not indulge in a little THCA goodness today?

                </p> <p className="mb-4">
                Indiana: THCA flower products are completely legal and there’s a wide range of options available for purchase. You won’t face any restrictions on the type or quantity of product you can buy, as long as you’re an adult. It’s worth noting that while cannabis laws in this state may be strict overall – with recreational and medical marijuana both prohibited – THCA flowers remain fully permitted for your enjoyment!

                </p> 
                <p className="mb-4">
                Iowa: THCA flower products are completely legal, which means that there is an abundance of options available for purchase. There are no restrictions on the types of THCA flower products you can buy or how much you can possess at any given time. As long as you’re over 18 years old, purchasing these items is a breeze! However, it’s important to note that medical marijuana laws only permit qualified patients access to this substance while recreational use remains prohibited by law. So why not take advantage of your right to enjoy safe and legal THCA flower today?

                </p>
                 <p className="mb-4">
                 Kansas: THCA flower products are completely legal and there is a wide range of options available for purchase. There are no restrictions on the types of THCA products that can be sold or how much you can buy or possess, as long as you’re an adult. However, it’s important to note that recreational and medical marijuana use remains prohibited at this time. So why not explore the world of THCA flower products today? With so many choices available legally, there has never been a better time to try something new!

                </p>
                <p className="mb-4">
                Kentucky: It’s completely legal to purchase THCA flower products without any restrictions on the type or amount of product. As long as you’re an adult, there are no limits when it comes to buying and possessing these amazing products. However, recreational marijuana is still illegal while medical marijuana has only recently been legalized in certain areas. So why wait? Get your hands on some high-quality THCA flower today!

                </p>
                <p className="mb-4">
                Louisiana: It’s worth noting that THCA flower products are completely legal, and there is no restriction on the types of items you can purchase. Additionally, there are no limits to how much you can buy or possess. As long as you’re an adult, purchasing these products is perfectly acceptable.

                </p>
                <p className="mb-4">
                While medical marijuana is available for patients who meet certain criteria, recreational use remains illegal at this time. However, if you’re interested in trying out THCA flower products for yourself – whether it be through smoking or other methods – rest assured that they are fully within your rights to do so!

                </p>
                <p className="mb-4">
                Maine: Legal THCA flower products are widely available without any restrictions on the types of items that can be sold or the quantity one may purchase or possess. As long as you’re an adult, purchasing these products is permissible in your state. Additionally, both medical and recreational marijuana sales are permitted by law to cater to a wide range of consumer preferences and needs. So why wait? Get yourself some high-quality THCA flower today!

                </p>
                <p className="mb-4">
                Maryland: THCA flower products are completely legal and there is no restriction on the types of products that can be sold. Furthermore, you have complete freedom when it comes to purchasing or possessing these amazing items without any limits whatsoever. As long as you’re an adult, THCA flower products can easily be purchased with ease. Additionally, both medical and recreational marijuana sales are permitted by the state which further highlights its legality for all users!

                </p>
                <p className="mb-4">
                Massachusetts: THCA flower products are completely legal, with no restrictions on the types of products that can be sold or the amount one may purchase and possess. As long as you’re an adult, purchasing THCA flower is entirely within your rights. It’s worth noting that both recreational and medical marijuana have been legalized in many areas – so there’s never been a better time to explore this incredible plant!

                </p>
                <p className="mb-4">
                Michigan: THCA flower products are completely legal and there is no restriction on the types of products that can be sold. Additionally, you don’t have to worry about any limits when it comes to purchasing or possessing these amazing items. As long as you’re an adult, THCA flower products can easily be purchased without any hassle whatsoever! It’s worth noting that both recreational and medical marijuana were also legalized by the state – so why not indulge yourself in some top-quality THC goodness today?

                </p>
                <p className="mb-4">
                Minnesota: It is crucial to note that the sale of THCA flower products in any form, whether online or physical stores, is prohibited by law. These items are illegal and should not be sold under any circumstances within the state’s jurisdiction. While medicinal marijuana use may be permitted as per regulations, recreational usage remains strictly forbidden according to existing laws. It is essential for all businesses operating within this industry to adhere strictly to these rules and refrain from engaging in unlawful activities that could lead them into trouble with authorities.

                </p>
                <p className="mb-4">
                Mississippi: It is important to note that THCA flower products are currently deemed illegal, making it unlawful for businesses to stock and sell them. Furthermore, online retailers are prohibited from delivering these items straight to your doorstep. Although Mississippi has legalized medical marijuana sales, the eligibility criteria are quite stringent while recreational usage remains entirely forbidden.

                </p>
                <p className="mb-4">
                Missouri: THCA flower products are completely legal and there is no restriction on the variety of items that can be sold. Additionally, you don’t need to worry about any limits when buying or possessing these products as long as you’re an adult. It’s important to note that only qualifying patients are permitted for medical use of marijuana whereas THCA flowers have a broader scope in terms of usage without any restrictions whatsoever! So why wait? Get your hands on some high-quality THCA flower today!

                </p>
                <p className="mb-4">
                Montana: It is imperative to note that THCA flower products are currently deemed illegal, which signifies that businesses cannot stock up on these items. Moreover, online retailers have been prohibited from delivering such products directly to your doorstep. It’s worth mentioning though that while the state has legalized recreational use of marijuana, its medical program remains intact and unchanged.

                </p>
                <p className="mb-4">
                Nebraska: THCA flower products are completely legal and readily available for purchase. There is no shortage of options when it comes to the types of THCA products that can be sold, nor any restrictions on how much you can possess or buy. As long as you’re an adult, buying these amazing products is a breeze! However, please note that hemp-based items containing more than 0% THC are strictly prohibited by state law.

                </p>
                <p className="mb-4">
                It’s important to keep in mind that while medical marijuana was only recently approved by the state in July 2022, recreational use remains illegal at this time. So if you want to enjoy all the benefits of THCA without worrying about breaking any laws or regulations – look no further than our top-quality selection today!

                </p>
                <p className="mb-4">
                Nevada: THCA flower products are completely legal and there is a wide range of options available for purchase. Not only that, but you can buy as much as you want without any restrictions on possession or quantity. As long as you’re an adult, these amazing products are at your disposal! Additionally, the state allows both recreational and medical marijuana sales so everyone can benefit from their incredible properties. Don’t hesitate to explore all the possibilities with THCA flower today!

                </p>
                <p className="mb-4">
                New Hampshire: THCA flower products are completely lawful, with no restrictions on the variety of items that can be sold or how much one may purchase and possess. As long as you’re an adult, purchasing THCA flower products is entirely legal. Although recreational marijuana usage remains illegal in this state, medical use for eligible patients is allowed while small amounts of possession have been decriminalized.

                </p>
                <p className="mb-4">
                New Jersey: THCA flower products are completely lawful and there is no restriction on the variety of items that can be marketed. Moreover, you have complete freedom to purchase or own as much THCA flower product as you desire provided that your age meets adult criteria. The state has granted permission for both medical and recreational marijuana usage making it easier than ever before to obtain these beneficial products legally!

                </p>
                <p className="mb-4">
                    Alabama: THCA flower products are completely lawful, with no restrictions on the variety of items available for sale. Furthermore, there is no limit to how much one can purchase or hold at any given time. As long as you’re an adult, acquiring THCA flower goods is a breeze. Additionally, Alabama has legalized medical marijuana for eligible patients and reduced penalties for possessing small amounts of cannabis in recent years – indicating that society’s attitude towards this plant is shifting positively!
                </p>
                <p className="mb-4">
                New Mexico: It is completely legal to purchase THCA flower products without any restrictions on the type of product or quantity. As long as you are an adult, there’s no stopping you from getting your hands on these amazing products! It’s worth noting that both recreational and medical marijuana are also allowed in this state. So why wait? Get yourself some high-quality THCA flower today!

                </p>
                <p className="mb-4">
                New York: It’s important to know that THCA flower products are completely legal and there is no restriction on the type of product or amount you can purchase. As long as you’re an adult, buying these products won’t be a problem for you. Moreover, New York has taken a great step by recently legalizing recreational marijuana while still maintaining its medical marijuana program for those who qualify. So why wait? Get your hands on some THCA flower products today!

                </p>
                <p className="mb-4">
                North Carolina: THCA flower products are completely legal and there is no restriction on the variety of items that can be sold. Additionally, you have complete freedom when it comes to purchasing or possessing them as long as you’re an adult. It’s worth noting that recreational marijuana remains illegal in this state, but qualifying medical patients may purchase cannabis products containing up to 0.6% THC for now.

                </p>
                <p className="mb-4">
                So if you want a safe and hassle-free way of enjoying THCA-infused goods without any legal repercussions, then these flowers should definitely be your go-to option! With their numerous benefits ranging from pain relief to anxiety reduction – not forgetting their delightful aroma – they truly offer something for everyone. So why wait? Treat yourself today with some top-quality THCA flower products – we promise you won’t regret it!

                </p>
                <p className="mb-4">
                North Dakota: THCA flower products are perfectly legal for purchase, with no restrictions on the types of products available or how much you can possess. As long as you’re an adult, buying THCA flower is a hassle-free process. Although North Dakota permits medical marijuana sales, recreational purchases remain illegal at this time

                </p>
                <p className="mb-4">
                Ohio: THCA flower products are completely legal, without any restrictions on the types of goods that can be sold or how much you may purchase or own. As long as you’re an adult, THCA flower items can easily be obtained. Medical sales for marijuana have been allowed for eligible patients and small amounts of possession have been decriminalized; however, recreational use is still not permitted.

                </p>
                <p className="mb-4">
                Oklahoma: THCA flower products are completely lawful, with no restrictions on the variety of items that can be marketed or the quantity that may be bought or owned. As long as you’re an adult, purchasing THCA flower goods is permitted by state law. While medical marijuana sales to eligible patients are authorized under certain circumstances, recreational cannabis use remains prohibited for now.

                </p>
                <p className="mb-4">
                Oregon: THCA flower products are currently deemed unlawful, making it illegal for businesses to sell or distribute them. Furthermore, online stores are prohibited from delivering THCA flower products straight to your doorstep. However, the sale of medical and recreational marijuana is permitted by law.

                </p>
                <p className="mb-4">
                Pennsylvania: THCA flower products are completely legal to purchase and possess without any restrictions on the types of products available. Additionally, there is no limit on how much you can buy or own as long as you’re an adult. This means that anyone who meets this requirement can enjoy all the benefits THCA has to offer! While recreational marijuana sales may be prohibited by state law, a medical program still exists for those in need. So don’t hesitate – get your hands on some high-quality THCA today!

                </p>
                <p className="mb-4">
                Rhode Island: The use of THCA flower products is currently considered illegal, which means that businesses are not allowed to sell or distribute them. Furthermore, online retailers cannot ship these items directly to your doorstep. However, it’s important to note that while the sale and distribution of recreational marijuana may be prohibited in some states, medical marijuana programs do exist for those who need it.

                </p>
                <p className="mb-4">
                On the other hand, if you’re an adult looking for a legal way to enjoy THCA flower products without breaking any laws – fear not! These types of products can still be legally purchased as long as you meet certain age requirements. There are no limits on what kinds of THC-containing goods can be sold either; nor is there a limit on how much one person may possess at any given time.

                </p>
                <p className="mb-4">
                South Carolina: THCA flower products are completely legal and there is no restriction on the variety of items that can be sold. Additionally, you have complete freedom to purchase or possess as much THCA flower product as you desire provided that you’re an adult. The state also strictly prohibits marijuana sales for both recreational and medicinal purposes, but fortunately THCA flowers fall outside this category so they remain a safe choice for those looking to indulge in their benefits without any fear of breaking the law.

                </p>
                <p className="mb-4">
                So why wait? If you want access to high-quality cannabis-derived extracts like THCA flowers but don’t want run afoul with law enforcement agencies – then look into purchasing from reputable vendors today!

                </p>
                <p className="mb-4">
                South Dakota: THCA flower products are completely legal and there’s no restriction on the variety of items that can be sold. Additionally, you’re free to purchase or possess as much THCA flower product as you desire provided that you’ve attained adulthood. However, it is crucial to note that any hemp item containing more than 0% THCA flowers is prohibited by law.

                </p>
                <p className="mb-4">
                Fortunately for qualifying patients in need of medical marijuana sales, such transactions are permissible under state laws; however recreational sales remain illegal at this time. Don’t hesitate to take advantage of your right to legally obtain high-quality THC-A rich cannabis strains today!

                </p>
                <p className="mb-4">
                Tennessee: THCA flower products are completely legal and there is no restriction on the types of products that can be sold. Additionally, you won’t face any limits when it comes to purchasing or possessing these amazing items. As long as you’re an adult, THCA flower products are readily available for purchase without any hassle whatsoever.

                </p>
                <p className="mb-4">
                It’s important to note that while the state has a strict anti-marijuana policy in place, this doesn’t apply to THCA flowers which have been deemed safe and legal by authorities. So don’t worry about running afoul of the law – simply enjoy all the benefits that come with using high-quality THCA flower products!

                </p>
                <p className="mb-4">
                Texas: THCA flower products are completely legal and unrestricted in terms of their sale. There is no limit to the variety or quantity that can be purchased or owned, as long as you’re an adult. While recreational marijuana remains prohibited by state law, qualifying medical patients may purchase only those products containing up to 0.5% THC content. So go ahead and indulge in THCA flower without any worries!

                </p>
                <p className="mb-4">
                Utah: It is imperative to note that THCA flower products are currently prohibited by law, rendering it illegal for businesses to stock them. Furthermore, online stores have been strictly instructed against shipping these items directly to your doorstep. It’s important also to mention that the recreational use of marijuana remains unlawful in this state; however, qualifying patients can enroll themselves into medical marijuana programs without any hassle.

                </p>
                <p className="mb-4">
                Vermont: THCA flower products are completely lawful and there is no restriction on the variety of items that can be marketed. Additionally, you have complete freedom to purchase or possess as much THCA flower product as you desire provided that you’re an adult. It’s worth noting that recreational and medical marijuana has been legalized in this state for quite some time now. So why wait? Take advantage of your right to buy THCA flowers today!

                </p>
                <p className="mb-4">
                    Alabama: THCA flower products are completely lawful, with no restrictions on the variety of items available for sale. Furthermore, there is no limit to how much one can purchase or hold at any given time. As long as you’re an adult, acquiring THCA flower goods is a breeze. Additionally, Alabama has legalized medical marijuana for eligible patients and reduced penalties for possessing small amounts of cannabis in recent years – indicating that society’s attitude towards this plant is shifting positively!
                </p>
                <p className="mb-4">
                Virginia: You can legally purchase THCA flower products without any restrictions on the type or quantity of product. As long as you are an adult, buying and possessing these products is completely legal in your state. Not only that, but both medicinal and recreational use of marijuana is permitted under state law. So why wait? Take advantage of this opportunity to experience all the benefits that THCA has to offer!

                </p>
                <p className="mb-4">
                Washington: THCA flower products are fully authorized and lawful for purchase. The range of available options is limitless, as there are no restrictions on the types of products that can be sold or possessed in any quantity. As long as you meet the age requirement, THCA flower products can easily be acquired without hassle. Additionally, both medical and recreational marijuana sales have been approved by state law to cater to all needs and preferences alike.

                </p>
                <p className="mb-4">
                West Virginia: THCA flower products are completely legal and there is an abundance of options available for purchase. The best part? There are no restrictions on the quantity you can buy or possess! As long as you’re of adult age, these amazing products can be yours to enjoy at your leisure. And if that wasn’t enough good news, medical marijuana is also legally accessible for qualifying patients who need it most – although recreational use still remains prohibited. So why wait any longer? Get yourself some THCA flower today and experience all the benefits this incredible plant has to offer!

                </p>
                <p className="mb-4">
                Wisconsin: THCA flower products are completely legal and unrestricted in terms of their sale. There is no limit on the variety or quantity that can be purchased or owned, as long as you’re an adult. However, it’s important to note that while marijuana sales for recreational and medical purposes remain prohibited by state law, low-THC cannabis oils may still be obtained by eligible patients with epilepsy. So if you want to enjoy THCA flowers without any worries about legality or restrictions – go ahead!

                </p>
                <p className="mb-4">
                Wyoming: THCA flower products are completely legal and there is an extensive range of options available for purchase. There are no restrictions on the quantity that can be bought or owned, making it easy to stock up on your favorite strains. As long as you’re a responsible adult, purchasing THCA flower products is perfectly acceptable under state law.
                </p>
            </div>
        </div>
    )
}
