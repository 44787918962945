// fetchData.js
import axios from 'axios';
import { useAppContext } from '../AppContextProvider';

// export const fetchData = async (selectedAdress) => {

//     const savedLocations = JSON.parse(localStorage.getItem('savedLocations'));
//     const selectedLocation = savedLocations?.find((location) => location.check);
//     try {
//         const token = localStorage.token;
//         const lati = token ? selectedAdress?.coordinates?.lat : selectedLocation?.lat;
//         const long = token ? selectedAdress?.coordinates?.lng : selectedLocation?.lng;

//         const response = await axios.get(`https://api.vapedelivery.com/api/main/avail-store?lat=${lati}&lng=${long}`, {
//             headers: {
//                 'Authorization': `Bearer ${token}` // Set the token in the Authorization header
//             },
//             withCredentials: false,
//         });
//         return response.data;

//     } catch (error) {
//         // navigate('/product');
//         return {};

//     }
// };



export const fetchUserData = async (setUserData, setSelectedAdress) => {
    const token = localStorage.token;
    let activeAddress;

    try {
        if (!token) {
            console.error('No token found in localStorage');
            return;
        }
        const response = await axios.get('https://api.vapedelivery.com/api/main/profile', {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: false,
        });
        setUserData(response.data);


        activeAddress = response.data?.addresses?.find(address => address.active === true);
        if (token) {
            fetchStoreData(activeAddress, setSelectedAdress)
        }

        setSelectedAdress(activeAddress); // Update address state once user data is fetched
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

export const fetchStoreData = async (selectedAdress, setSelectedAdress) => {
    const token = localStorage.token;
    const savedLocations = JSON.parse(localStorage.getItem('savedLocations'));
    const selectedLocation = savedLocations?.find((location) => location.active);

    try {
        const lati = token ? selectedAdress?.coordinates?.lat : selectedLocation?.lat;
        const long = token ? selectedAdress?.coordinates?.lng : selectedLocation?.lng;

        const response = await axios.get(`https://api.vapedelivery.com/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: false,
        });

        if (response.data?.storeName) {

            setSelectedAdress(prevFormData => ({
                ...prevFormData,
                ...response.data,
            }));
        } else {
            cheqapi(setSelectedAdress)
        }

    } catch (error) {

        cheqapi(setSelectedAdress)

    }

};

export const cheqapi = async (setSelectedAdress) => {
    try {

        // Await the axios.get call to ensure the data is loaded before proceeding
        const response = await axios.get('https://api.vapedelivery.com/api/main/products', {
            withCredentials: false
        });
        const data = response.data;
        // Filtering the data based on the store._id
        const filtered_data = data.filter(x => x?.store?._id === '6675cbcdf34a17ea3d0de9fa');
        // Sorting the filtered data by the name property
        const sortedData = filtered_data.sort((a, b) => a.name.localeCompare(b.name));

        setSelectedAdress(prevFormData => ({
            ...prevFormData,
            products: sortedData,
        }));
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};



export const capitalizeName = (name) => {
    if (!name) return '';
    return name.split(' ').map(word => {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    }).join(' ');
}

export const copyToClipboard = (shareLink, setCopySuccess) => {
    navigator.clipboard.writeText(shareLink)
        .then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
        })
        .catch((err) => console.error('Failed to copy!', err));
};