import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';  // Make sure to import this

export default function TermsAndCondition() {
    const { pathname } = useLocation(); // Ensure useLocation is properly imported

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top whenever the pathname changes
    }, [pathname]);

    return (
        <div className="text-start max-w-screen-xl">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">TERMS AND CONDITION </h1>
                <h4 className="text-xl text-gray-500 font-bold mb-4">All CUSTOMERS using VAPEDELIVERY.COM (VAPEDELIVERY) Services Agree to the following terms and conditions:
                </h4>


                <ul className="list-disc list-inside mb-4">
                    <li>Use of Site services requires that you register and/or create an account ("Account") or use the Site as a guest. To register and create an Account, you must select an account designation and password and provide certain personal information. In consideration of the use of the Site's services, you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the registration form, and (b) maintain and promptly update the personal information you provide to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Vape delivery has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Vape delivery has the right to refuse any and all current or future use of the Site (or any portion thereof).</li>

                    <li>You are responsible for maintaining the confidentiality and security of your Account and password, and you are fully responsible for all activities that occur under your password or Account, and for any other actions taken in connection with the Account or password. You agree to (a) immediately notify Vape delivery of any known or suspected unauthorized use(s) of your password or Account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your password or credit card information; and (b) ensure that you exit from your Account at the end of each session. Vape delivery will not be liable for any injury, loss or damage of any kind arising from or relating to your failure to comply with (a) and (b) or for any acts or omissions by you or someone else using your Account and/or password.</li>

                    <li>When making any purchases of age restricted products, including, but not limited to, CBD, E-cigarettes and tobacco, you must confirm that you are of legal age to purchase these goods in compliance with the laws of your state, county and city. Stores have the right to refuse delivery of these goods if you are unable to provide proper government issued identification</li>

                    <li>If you are not home when the driver arrives, if the customer provides an incorrect address when placing an order, or if the customer is not of legal age to receive the order, you may be charged a fee of $5 to cover the cost of the driver.
                        Limitation of Liability</li>
                    <li>IN NO EVENT SHALL VAPE DELIVERY  BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OR DAMAGES WHATSOEVER (EVEN IF VAPE DELIVERY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER IN AN ACTION UNDER CONTRACT, NEGLIGENCE, OR ANY OTHER THEORY, IN ANY MANNER ARISING OUT OF OR IN CONNECTION WITH THE USE, INABILITY TO USE, PERFORMANCE OF, OR SERVICES PROVIDED ON OR THROUGH THE SITE. VAPE DELIVERY ASSUMES NO RESPONSIBILITY AND SHALL NOT BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, BROWSING OF, OR DOWNLOADING OF ANY MATERIAL FROM THE SITE. VAPE DELIVERY ASSUMES NO RESPONSIBILITY OR LIABILITY IN ANY MANNER ARISING OUT OF OR IN CONNECTION WITH ANY INFORMATION, CONTENT, PRODUCTS, SERVICES, OR MATERIAL AVAILABLE ON OR THROUGH THE SITE, AS WELL AS ANY THIRD PARTY WEBSITE PAGES OR ADDITIONAL WEBSITES LINKED TO THIS SITE, FOR ANY ERROR, DEFAMATION, LIBEL, SLANDER, OMISSION, FALSEHOOD, OBSCENITY, PORNOGRAPHY, PROFANITY, DANGER, INACCURACY CONTAINED THEREIN OR HARM TO PERSON OR PROPERTY CAUSED THEREBY. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL VAPE DELIVERY’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE, EXCEED (A) THE AMOUNT PAID BY YOU TO VAPE DELIVERY OR A RESTAURANT AFFILIATE, IF ANY, OR (B) $100 (WHICHEVER IS LESS).
                        YOU AND VAPE DELIVERY AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE MATERIAL, BARGAINED-FOR BASES OF THIS AGREEMENT, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN DETERMINING THE CONSIDERATION TO BE GIVENBY EACH PARTY UNDER THIS AGREEMENT AND IN THE DECISION BY EACH PARTY TO ENTER INTO THIS AGREEMENT. YOU AND VAPE DELIVERY AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE FAIR AND REASONABLE.
                        NOTWITHSTANDING ANY CONTRARY PROVISIONS ABOVE.
                        IF YOU ARE DISSATISFIED WITH THE SITE OR DO NOT AGREE TO ANY PROVISIONS OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE</li>
                </ul>
            </div>
        </div>
    );
}