import React, { useEffect, } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function PaymentSucess() {
    const location = useLocation();
    const { payment } = location.state || {};
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    },);

    return (
        <div className={'max-w-screen-xl md:flex flex-wrap my-6 items-center py-6  justify-center gap-4  w-full '}>
            <div className="p-4  w-full md:w-[35%]  flex flex-wrap items-center justify-center cursor-pointer">
                <div className="p-8 w-full bg-white   rounded-xl shadow-gray-300 shadow-2xl group">
                    <div className="  flex items-center justify-center  transition-transform">
                        {/* <img className=" w-24   " src={employee} alt="" /> */}
                        <span className="relative flex h-12 w-12 mb-4">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 30C0 22.0435 3.16071 14.4129 8.7868 8.7868C14.4129 3.16071 22.0435 0 30 0C37.9565 0 45.5871 3.16071 51.2132 8.7868C56.8393 14.4129 60 22.0435 60 30C60 37.9565 56.8393 45.5871 51.2132 51.2132C45.5871 56.8393 37.9565 60 30 60C22.0435 60 14.4129 56.8393 8.7868 51.2132C3.16071 45.5871 0 37.9565 0 30ZM28.288 42.84L45.56 21.248L42.44 18.752L27.712 37.156L17.28 28.464L14.72 31.536L28.288 42.84Z" fill="#3BA424" />
                            </svg>
                        </span>

                    </div>
                    <div className=" justify-center text-center text-gray-700 border-b pb-4 ">
                        <p className="block font-semibold text-xl  pt-3">Payment Successful</p>
                        <p className="block text-gray-400 text-md pb-3 pt-2 leading-5"> Your Order Has Been Placed. See You Soon!</p>
                    </div>

                    <div className='flex  justify-between my-2 w-full'>
                        <label className=' w-1/2 '><b>SubTotal</b></label>
                        <label className=" text-end w-1/2"><b>$
                            {payment.subtotal}
                        </b></label>
                    </div>
                    <div className='flex  justify-beteen my-2 w-full'>
                        <label className=' w-1/2 '><b>Tax:</b></label>
                        <label className="text-end w-1/2"><b>$
                            {payment.tax}
                        </b></label>
                    </div>


                    {payment.discount && (
                        <div className='flex justify-between my-2 w-full'>
                            <label className='w-1/2 text-red-500'><b>Discount:</b></label>
                            <label className="text-end w-1/2 text-red-500"><b>- $
                                {payment.discount}
                            </b></label>
                        </div>
                    )}
                    {payment.firstdiscount && (
                        <div className='flex justify-between my-2 w-full'>
                            <label className='w-1/2 text-red-500'><b>First Order Discount:</b></label>
                            <label className="text-end w-1/2 text-red-500"><b>- $
                                {payment.firstdiscount}
                            </b></label>
                        </div>
                    )}
                    <div className='flex   pb-2 justify-beteen my-2 w-full'>
                        <label className=' w-1/2 '><b>Delivery:</b></label>
                        <label className=" text-end w-1/2"><b>$
                            {payment.deliveryCharge}
                        </b></label>
                    </div>
                    <div className='flex  border-b border-t py-4 justify-beteen my-2 w-full'>
                        <label className=' w-1/2 '><b>Total:</b></label>
                        <label className=" text-end w-1/2"><b>$
                            {payment.total}
                        </b></label>
                    </div>
                    <div className='flex justify-center'>
                        <button className="flex justify-center shadow my-3 mt-6 self-center rounded-full bg-orange-500 text-white text-xs font-bold px-4 py-1 leading-none flex items-center  hover:bg-orange-600  hover:text-white duration-500 hover:scale-105"
                            onClick={() => { navigate('/product'); localStorage.removeItem("appValue"); window.location.reload() }}>
                            {/* <span className="block font-semibold text-xl"> {item.name}</span> */}
                            <span className="block p-2 text-md">Continue Shopping</span>
                        </button>
                    </div>
                </div>
            </div >
        </div>
    )
}
