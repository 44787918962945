import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Footer() {
    let navigate = useNavigate();
    return (
        <div className="bg-white border-gray-200 pt-10 border-t text-gray-700 w-full mt-4">
            <div className="max-w-screen-xl flex flex-wrap items-center bg-white justify-between mx-auto p-4">
                <div className='w-full md:flex justify-around '>
                    <div className='md:w-2/3 p-3'>
                        <p className=" text-start md:text-2xl mb-2  font-semibold border-l-4 pl-2 border-orange-600 text-gray-700">Disclaimer </p>
                        <p className='text-start text-md font-normal md:w-1/2  text-gray-700'>These products are not FDA evaluated and are not meant to diagnose, treat, cure, or prevent any disease. Vapor Punk LLC is not liable for any misuse of the information on this site. We assume no responsibility for usage of this information and will not be liable for any resulting damages.</p>
                    </div>
                    <div className='md:w-2/3 p-3'>
                        <p className=" text-start md:text-2xl mb-2  font-semibold border-l-4 pl-2 border-orange-600 text-gray-700">THCA Disclaimer
 </p>
                        <p className='text-start text-md font-normal md:w-1/2  text-gray-700'>We do not ship THCA products to the following states where THCA is restricted or illegal: Alaska, Arkansas, Colorado, Delaware, Hawaii, Idaho, Iowa, Minnesota, Montana, Nevada, New Hampshire, New York, North Dakota, Oregon, Rhode Island, South Dakota, Utah, Vermont, Washington</p>
                    </div>
                    <div className='md:w-1/3 p-3'>
                        <p className=' text-start md:text-2xl  font-semibold border-l-4 pl-2 border-orange-600 text-gray-700'>Contact Us</p>
                        <p className=' text-start pl-4 py-2 '>vapedelivery.com</p>
                        <p className=' text-start pl-4 py-2 '>Address: 1002 liberty St, Erie, PA 16502</p>
                        {/* <p className=' text-start py-2 pl-4'>Call Us:<b> (814) 520-5184</b></p> */}
                        <p className=' text-start font-semibold pl-4 hover:text-blue-400'>vapedeliveryerie@gmail.com</p>
                    </div>
                    {/* <div className='md:w-1/3 p-3'>
                            <p className=' text-start md:text-2xl font-semibold border-l-4 pl-2 border-orange-600 text-gray-700'>Sign Up for Email Updates</p>
                            <div className=" flex flex-wrap items-end  my-4  rounded ">
                                <input className=" rounded-md bg-white p-2" type="email" name="email" placeholder="Your e-mail adress" required="" />
                                <button className=" bg-orange-400 rounded-xl p-2" type="submit" >Subscribe</button>
                            </div>
                            <p className='text-start'>
                                Sign up with your email address to receive news and updates</p>
                        </div> */}
                </div>
                <div className='w-full md:flex justify-center items-center border-t py-2 mt-12 pb-3'>
                    <div className=' text-start '>Copyright ©2024 Vapor Punk LLC. All rights reserved.</div>
                    <div onClick={() => navigate('/policy')} className='cursor-pointer text-blue-400 text-start px-1 hover:text-blue-500 border-b hover:border-blue-400 py-2'>Privacy Policy<label className='text-gray-700'>, </label></div>
                    <div onClick={() => navigate('/terms')} className='cursor-pointer text-start text-blue-400 px-1 hover:text-blue-500 border-b hover:border-blue-400 py-2'>Terms And Conditions,</div>
                    <div onClick={() => navigate('/returns')} className='cursor-pointer text-start text-blue-400 px-1 hover:text-blue-500 border-b hover:border-blue-400 py-2'>Returns Policy,</div>
                    <div onClick={() => navigate('/THCADisclaimer')} className='cursor-pointer text-start text-blue-400 px-1 hover:text-blue-500 border-b hover:border-blue-400 py-2'>THCA Disclaimer</div>

                </div>
            </div>
        </div>
    )
}
